<template>
  <a-spin :spinning="loading">
    <div>
      <pdf
        v-for="i in loadPages"
        :key="i"
        ref="pdf"
        :class="`page-no page-${i}`"
        :src="url"
        :page="i"
        @error="pdfError"
      />
    </div>

    <div class="tools sfm-flex sfm-items-center sfm-justify-center">
      <div class="mr-20" v-show="!loading">{{ loadPages }} / {{ pagesCount }}</div>

      <a-button
        v-show="this.loadPages < this.pagesCount"
        type="primary"
        @click.stop="nextPdfPages"> 下 {{ loadPagesCountPerTime }} 页
      </a-button>
    </div>
  </a-spin>
</template>

<script>
import pdf from 'vue-pdf'
import {message} from 'ant-design-vue'

export default {
  name: 'PreviewPdf',
  components: {
    pdf
  },
  props: {
    fileUrl: {
      type: String,
      require: true
    },
  },
  data() {
    return {
      loading: true,
      url: '',
      pagesCount: 0,
      loadPagesCountPerTime: 5, // 每次加载页数
      loadTimes: 1, // 加载次数
    }
  },
  errorCaptured() {
    return false
  },
  mounted() {
    this.url = pdf.createLoadingTask(this.fileUrl)
    this.url.promise.then(pdf => {
      this.pagesCount = pdf.numPages
      this.loading = false
    });
  },
  computed: {
    loadPages() {
      let pages = this.loadPagesCountPerTime * this.loadTimes
      if (pages > this.pagesCount) {
        pages = this.pagesCount
      }
      return pages
    }
  },
  methods: {
    nextPdfPages() {
      if (this.loadPages < this.pagesCount) {
        this.loadTimes += 1
      } else {
        message.warning({
          content: "到底啦~",
          duration: 1
        })
      }
    },

    // 其他的一些回调函数。
    pdfError(error) {
      console.error(error)
    },
  }
}
</script>

<style lang="scss" scoped>
.page-no {
  padding: 10px 0;

  &:not(:last-child) {
    border-bottom: 1px solid #e1e1e1;
  }
}
</style>